.monthgrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 25%);
  border: 1px solid #e3d9a8;
}

.monthgrid-month {
  border: 1px solid #e3d9a8;
  padding: .125rem;
}
.monthgrid-month p {
  border-radius: .125rem;
  padding: .125rem;
  background-color: transparent;
  margin: 0px;
}
.monthgrid-month.active p {
  background-color: #c3ba00;
}
.monthgrid-month.current {
  border: 1px solid #c74720;
}