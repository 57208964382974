.guideline-container {
  position: relative;
}

.guideline {
  position: absolute;
  bottom: 0px;
  height: 30px;
  padding-left: 2px;
  border-left: 1.5px solid #e3d9a8;
}

.guideline.short {
  height: 22px;
  border-left: 1px solid #e3d9a8;
}

.guideline.partial {
  bottom: 4px;
  height: 12px;
  border-left: 1px solid #e3d9a8;
}

.guideline-text {
  font-size: 0.5rem;
  margin: 0px;
}
