@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
.infobar {
  position: fixed;
  display: flex;
  visibility: visible;
  bottom: 0;
  width: 100%;
  background-color: #f6f5e1;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 2em;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-animation-name: infobar-in;
          animation-name: infobar-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  transition: visibility 0s;
}
.infobar.invisible {
  visibility: hidden;
}

@-webkit-keyframes infobar-in {
  from {
    bottom: -50px;
  }

  to {
    bottom: 0;
  }
}

@keyframes infobar-in {
  from {
    bottom: -50px;
  }

  to {
    bottom: 0;
  }
}

.infobar__close {
  border: none;
  padding: 0.25em 0.5em;
  background-color: transparent;
  font-size: 1.2rem;
  border-radius: 0.125rem;
}
.infobar__close:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.critter-btn {
  background-color: #f6f5e1;
  border-radius: 50%;
  height: 48px;
  min-height: 48px;
  width: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.critter-btn:hover {
  cursor: pointer;
}

.critter-btn__img {
  height: 32px;
}
.filterbar-container {
  display: flex;
  justify-content: center;
  padding: 1em 2em;
}

.filterbar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterbar-search {
  background-color: #f6f5e1;
  border-radius: 0.125rem;
  border: none;
  width: 500px;
  height: 2.5rem;
  padding: 0rem 1rem 0px;
  line-height: 1.2;
  font-size: 1rem;
}

.filterbar-filters {
  display: flex;
  margin-top: 0.25rem;
}
.filterbar-filters__types {
  height: 48px;
  display: flex;
  align-items: center;
}
.filterbar-filters__select {
  display: flex;
  align-items: center;
}

.filterbar-filters__divider {
  width: 0.15rem;
  height: 80%;
  background-color: rgb(246, 245, 225);
  margin: 0em 0.5em;
}

.filterbar__active-button {
  background-color: transparent;
  border: 2px solid #f6f5e1;
  border-radius: 0.125rem;
  padding: 0.75em 1em;
  color: #727168;
}

.filterbar__active-button.active {
  background-color: #f6f5e1;
  color: #000;
}

.filterbar__active-button:hover {
  cursor: pointer;
}

.select-filters__spacer {
  width: 0.25rem;
}

@media screen and (max-width: 768px) {
  .filterbar-container {
    padding: 1em;
  }

  .filterbar-search {
    width: 100%;
  }

  .filterbar-filters__select {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .filterbar-filters__select > button {
    margin-bottom: .5em;
  }

  .select-filters__spacer {
    height: 0.25rem;
  }
}

.timescale-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  border-bottom: 2px solid #e3d9a8;
  padding-bottom: 1px;
  padding-top: 8px;
}

.timescale-block {
  height: 14px;
  background-color: transparent;
}

.timescale-block.active {
  background-color: #c3ba00;
}

.timescale-block.start {
  border-radius: 50% 0px 0px 50%;
}

.timescale-block.end {
  border-radius: 0px 50% 50% 0px;
}

.guideline-container {
  position: relative;
}

.guideline {
  position: absolute;
  bottom: 0px;
  height: 30px;
  padding-left: 2px;
  border-left: 1.5px solid #e3d9a8;
}

.guideline.short {
  height: 22px;
  border-left: 1px solid #e3d9a8;
}

.guideline.partial {
  bottom: 4px;
  height: 12px;
  border-left: 1px solid #e3d9a8;
}

.guideline-text {
  font-size: 0.5rem;
  margin: 0px;
}

.monthgrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 25%);
  border: 1px solid #e3d9a8;
}

.monthgrid-month {
  border: 1px solid #e3d9a8;
  padding: .125rem;
}
.monthgrid-month p {
  border-radius: .125rem;
  padding: .125rem;
  background-color: transparent;
  margin: 0px;
}
.monthgrid-month.active p {
  background-color: #c3ba00;
}
.monthgrid-month.current {
  border: 1px solid #c74720;
}
.crittercard {
  border: 1px solid #e3d9a8;
  padding: 1em;
}

.crittercard__header {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}
.crittercard__header img {
  height: 24px;
  margin-right: .25rem;
}
.crittercard__header h3 {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0px;
}

.crittercard__line {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
}
.crittercard__line--title {
  color: rgb(68, 73, 5);
  background-color: rgb(214, 210, 135);
  border-radius: 0.125rem;
  padding: 0em 0.1rem;
  line-height: 1.2;
  font-size: 0.9rem;
  margin: 0px;
}
.crittercard__line p {
  margin: 0px;
  font-size: 0.9rem;
}


.crittergrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 25%);
  list-style: none;
  padding: 0px;
  margin: 0px;
  border: 1px solid #e3d9a8;
  margin: 0em 2em 5em;
}

@media screen and (max-width: 768px) {
  .crittergrid {
    margin: 0em 1em;
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

* {
  box-sizing: border-box;
  font-family: 'Varela Round', sans-serif;
}

body {
  background-color: #f5eabd;
  margin: 0px;
  padding: 0px;
}

.spacer {
  width: 0.25rem;
}

.info-message {
  margin: 0px;
}
.info-message a {
  color: #c3ba00;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  position: relative;
  padding: .75em 1.5em .75em .5em;
  border: none;
  border-radius: .125rem;
  background-color: #f6f5e1;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23e3d9a8%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
  background-size: .6em auto, 100%;
  min-width: 125px;
}
select:hover {
  cursor: pointer;
}

