.filterbar-container {
  display: flex;
  justify-content: center;
  padding: 1em 2em;
}

.filterbar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterbar-search {
  background-color: #f6f5e1;
  border-radius: 0.125rem;
  border: none;
  width: 500px;
  height: 2.5rem;
  padding: 0rem 1rem 0px;
  line-height: 1.2;
  font-size: 1rem;
}

.filterbar-filters {
  display: flex;
  margin-top: 0.25rem;
}
.filterbar-filters__types {
  height: 48px;
  display: flex;
  align-items: center;
}
.filterbar-filters__select {
  display: flex;
  align-items: center;
}

.filterbar-filters__divider {
  width: 0.15rem;
  height: 80%;
  background-color: rgb(246, 245, 225);
  margin: 0em 0.5em;
}

.filterbar__active-button {
  background-color: transparent;
  border: 2px solid #f6f5e1;
  border-radius: 0.125rem;
  padding: 0.75em 1em;
  color: #727168;
}

.filterbar__active-button.active {
  background-color: #f6f5e1;
  color: #000;
}

.filterbar__active-button:hover {
  cursor: pointer;
}

.select-filters__spacer {
  width: 0.25rem;
}

@media screen and (max-width: 768px) {
  .filterbar-container {
    padding: 1em;
  }

  .filterbar-search {
    width: 100%;
  }

  .filterbar-filters__select {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .filterbar-filters__select > button {
    margin-bottom: .5em;
  }

  .select-filters__spacer {
    height: 0.25rem;
  }
}
