@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Varela Round', sans-serif;
}

body {
  background-color: #f5eabd;
  margin: 0px;
  padding: 0px;
}

.spacer {
  width: 0.25rem;
}

.info-message {
  margin: 0px;
}
.info-message a {
  color: #c3ba00;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  position: relative;
  padding: .75em 1.5em .75em .5em;
  border: none;
  border-radius: .125rem;
  background-color: #f6f5e1;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23e3d9a8%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
  background-size: .6em auto, 100%;
  min-width: 125px;
}
select:hover {
  cursor: pointer;
}
